<template>
	<div class="mod-password-strength">
		<div class="progress">
			<div class="progress-outer">
				<div class="progress-inner" :style="{ 'background-color': index > 0 && index < 4 ? colors[index] : '' }" />
			</div>
			<div class="progress-outer">
				<div class="progress-inner" :style="{ 'background-color': index > 1 && index < 4 ? colors[index] : '' }" />
			</div>
			<div class="progress-outer">
				<div class="progress-inner" :style="{ 'background-color': index > 2 && index < 4 ? colors[index] : '' }" />
			</div>
		</div>
		<span class="strength-tips">{{ tips[index] || '' }}</span>
	</div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: () => 0
    }
  },
  data () {
    return {
      tips: ['', '弱', '中', '强'],
      colors: ['', '#d9001b', '#f59a23', '#70b603']
    }
  },
  computed: {
    index: function () {
      return typeof this.value === 'number' && this.value > 0 && this.value < 4 ? this.value : 0
    }
  }
}
</script>

<style lang="less" scoped>
.mod-password-strength{
  height: 10px;
  width: 160px;
  display: inline-block;
  line-height: 10px;
  position: relative;
  .progress{
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding-right: 30px;
    padding-top: 2px;
    display: flex;
    .progress-outer{
      height: 6px;
      background-color: #ebeef5;
      overflow: hidden;
      position: relative;
      vertical-align: middle;
      width: 40px;
      margin-right: 5px;
      &:last-child{
        margin-right: 0;
      }
      .progress-inner{
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background-color: #ebeef5;
        text-align: right;
        line-height: 1;
        white-space: nowrap;
        transition: width .6s ease;
        &::after{
          display: inline-block;
          content: "";
          height: 100%;
          vertical-align: middle;
        }
      }
    }
  }
  .strength-tips{
    position: absolute;
    top: 0;
    right: 0;
    color: #7f7f7f;
    font-size: 12px;
  }
}
</style>
