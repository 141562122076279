import Api from '@/apis/index.js'

export default class Server extends Api {
  getUserMessage (cb, err) {
    const userInfo = window.global._auth.userInfo
    this.$http.get(`${Api.path.apiCrmOS}partner/${userInfo.partnerId}/employ/${userInfo.employeeId}`, { params: { ownPort: 0, employeeId: userInfo.employeeId }}).then(res => {
      cb(res)
    }, this.error(err))
  }

  putModifyUser (userId, params) {
    return this.$http.put(`${Api.path.apiCrmUs}user/${userId}/password`, params)
  }

  checkPassword (opt) {
    return this.$http.get(`${Api.path.apiCrmUs}user/${window.global._auth.getUserId()}/checkPassword`, { params: opt })
  }

  // 修改密码
  findPassword (o) {
    return this.$http.put(`${Api.path.apiCrmUs}forget-pwd/user/password`, o)
  }

  // 发送短信验证码
  postPhoneCode (o) {
    return this.$http.post(`${Api.path.apiCrmUs}send/msg/verifi-code`, o)
  }

  // 校验短信验证码
  checkPhoneCode (o) {
    return this.$http.post(`${Api.path.apiCrmUs}proof/msg/verifi-code`, o)
  }
}
