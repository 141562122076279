<template>
	<div class="mod-password-modify">
		<div class="setting-container">
			<el-steps :active="active" align-center>
				<el-step title="验证身份" />
				<el-step title="修改密码" />
				<el-step title="完成" />
			</el-steps>
			<div v-if="active === -1" class="switch-type">
				<h3 class="title">为确认是你本人操作，请完成身份验证：</h3>
				<!-- <div class="type-item">
          <div class="info">
            <h5 class="sub-title">通过【手机验证码】</h5>
            <p class="tips">如忘记旧密码，且手机还在正常使用，请选择此方式</p>
          </div>
          <div class="option">
            <el-button type="primary" @click="handleSwitch(0)">立即修改</el-button>
          </div>
        </div> -->
				<div class="type-item">
					<div class="info">
						<h5 class="sub-title">通过【旧密码】</h5>
						<!-- <p class="tips">如仍记得旧密码，请选择此方式</p> -->
					</div>
					<div class="option">
						<el-button type="primary" @click="handleSwitch(1)">立即修改</el-button>
					</div>
				</div>
			</div>
			<div v-if="active === 0 && type === 1" class="check-old-pass">
				<jw-form ref="checkPass" v-bind="checkPass" />
				<p class="button-area">
					<el-button type="primary" @click="handleCheckPass">保存</el-button>
					<el-button @click="handleCancel">取消</el-button>
				</p>
				<div class="tips-area">
					<h5 class="title">原密码注意事项：</h5>
					<ul class="text-area">
						<li>1、注意密码字母的大小写；</li>
						<li>2、注意密码的输入长度是否少于6位；</li>
					</ul>
				</div>
			</div>
			<div v-if="active === 0 && type === 0" class="mobile-password">
				<el-form ref="phoneForm" :model="phoneForm" :rules="phoneRules" label-width="100px">
					<el-form-item prop="account" label="手机号码">
						<span>{{ setPhone(phoneForm.account) }}</span>
					</el-form-item>
					<el-form-item prop="code" label="验证码">
						<el-input
							ref="phoneFormCode"
							v-model.trim="phoneForm.code"
							placeholder="请输入短信验证码"
							maxlength="6"
							class="input-code"
							@input="onClearCodeInput"
							@keyup.enter.native="phoneSubmit"
							>
							<code-button slot="suffix" style="margin-right:6px;" @change="onGetCode" />
						</el-input>
					</el-form-item>
					<el-form-item class="login-submit">
						<el-button
							type="primary"
							:loading="phoneloading"
							class="login-btn"
							@click="onSubmitPhoneForm"
							>{{ phoneloading?'提交中':'确定' }}</el-button>
						<el-button @click="handleCancel">取消</el-button>
					</el-form-item>
				</el-form>
				<div class="tips-area">
					<h5 class="title">没收到短信验证码？</h5>
					<ul class="text-area">
						<li>1、网络通讯异常可能会造成短信丢失，请重新获取或稍后再试。</li>
						<li>2、请核实手机是否已欠费停机，或者屏蔽了系统短信。</li>
						<li>3、如果手机已丢失或停用， 请选择其他验证方式 。</li>
						<li>4、您也可以尝试将SIM卡移动到另一部手机，然后重试。</li>
						<li>5、请检查输入的手机号码是否有误</li>
					</ul>
				</div>
			</div>
			<div v-if="active === 1" class="modified-pass">
				<jw-form ref="modifiedPass" v-bind="modifiedPass" />
			</div>
			<div v-if="active === 2" class="modified-success">
				<i class="el-icon-success success-icon" />
				<div class="tips-area">
					<h5 class="title">修改成功，请记住新密码</h5>
					<p class="text"><span class="count-down">{{ countdown }}s</span>后自动退出，请重新登录…</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="jsx">
import Server from './apis.js'
import passwordStrength from './modify-length.vue'
import codeButton from './code-button.vue'
import cookie from '@jw/ability-business-components/tools/lib/cookie'
export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    passwordStrength,
    codeButton
  },
  data () {
    const rule = /[\s\u2E80-\u2EFF\u2F00-\u2FDF\u3000-\u303F\u31C0-\u31EF\u3200-\u32FF\u3300-\u33FF\u3400-\u4DBF\u4DC0-\u4DFF\u4E00-\u9FBF\uF900-\uFAFF\uFE30-\uFE4F\uFF00-\uFFEF]+/gm
    const self = this
    const checkCode = (rule, value, callback) => {
      if (self.shouldCheckTotal) {
        if (self.checkFormOfSubmit && !value) {
          callback(new Error('请输入验证码'))
        } else {
          callback(new Error('操作过于频繁，请休息5分钟再试'))
        }
      } else {
        if (!value) {
          callback(new Error('请输入验证码'))
        } else if (value.length < 6) {
          callback(new Error('请输入6位数验证码'))
        } else if (!self.phoneCodeOk) {
          callback(new Error(self.phoneCodeMsg))
        } else {
          callback()
        }
      }
    }
    return {
      active: -1,
      type: null,
      countdown: 5,
      oldPassword: null,
      phoneForm: {
        account: '',
        code: ''
      },
      shouldCheckTotal: false,
      checkFormOfSubmit: false,
      phoneloading: false,
      phoneCodeOk: true,
      phoneCodeMsg: '',
      errMsg: '',
      phoneRules: {
        code: [{ validator: checkCode, trigger: 'blur' }]
      },
      checkPass: {
        read: false,
        bind: {
          domProps: {
            autocomplete: 'off'
          },
          props: {
            labelWidth: '302px',
            rules: {
              oldPassword: {
                trigger: 'blur',
                validator (rule, value, callback) {
                  if (!value) {
                    callback(new Error('不能为空！'))
                  } else if (value.length < 6 || value.length > 16) {
                    callback(new Error('请输入6-16位数的密码'))
                  } else {
                    self.$apis.checkPassword({ userPasswd: value }).then(res => {
                      if (Number(res.data.isRight) === 1) {
                        callback()
                      } else {
                        callback(new Error('原密码错误！'))
                      }
                    }).catch((response) => {
                      Server.$message(self, response)
                    })
                  }
                }
              }
            }
          }
        },
        data: [{
          label: '原密码',
          prop: 'oldPassword',
          render (h, store, value) {
            h = self.$createElement
            return <el-input value={value.oldPassword} style='width:240px'
              maxlength='16' type={ value.oldPasswdType ? 'text' : 'password' } nativeOnInput={(e) => { e.target.value = e.target.value.replace(rule, '') }}
              onInput={(e) => { value.oldPassword = e }} placeholder='请输入原密码'>
              <i slot={'suffix'} onClick={() => { self.$refs.checkPass.$setValueField('oldPasswdType', !value.oldPasswdType) }}
                class={`${value.oldPasswdType ? 'mps-icon icon-yanjing' : 'crm-icon icon-yanjing-bi'} mod-password-eyes`}></i>
            </el-input>
          }
        }],
        value: {
          oldPassword: null,
          oldPasswdType: false
        }
      },
      modifiedPass: {
        read: false,
        bind: {
          domProps: {
            autocomplete: 'off'
          },
          props: {
            labelWidth: '282px',
            rules: {
              userPasswd: {
                required: true,
                trigger: 'blur',
                validator (rule, value, callback) {
                  if (!value) {
                    callback(new Error('不能为空！'))
                  } else if (value.length < 6 || value.length > 16) {
                    callback(new Error('请输入6-16位数的密码'))
                  } else if (self.validatorPass(value) < 2) {
                    callback(new Error('密码至少6位且含字母、数字、符号中2种'))
                  } else {
                    callback()
                  }
                }
              },
              userPasswdConfirm: {
                required: true,
                trigger: 'blur',
                validator (rule, value, callback) {
                  const { userPasswd, userPasswdConfirm } = self.$refs.modifiedPass.$getValue()
                  if (!value) {
                    callback(new Error('不能为空！'))
                  } else if (value.length < 6 || value.length > 16) {
                    callback(new Error('请输入6-16位数的密码'))
                  } else if (self.validatorPass(value) < 2) {
                    callback(new Error('密码至少6位且含字母、数字、符号中2种'))
                  } else if (userPasswd && userPasswd !== userPasswdConfirm) {
                    callback(new Error('密码输入不一致，请重新输入！'))
                  } else if (self.errMsg) {
                    callback(new Error(self.errMsg))
                  } else {
                    callback()
                  }
                }
              }
            }
          }
        },
        data: [{
          label: '新的登录密码',
          prop: 'userPasswd',
          render (h, store, value) {
            h = self.$createElement
            return [<el-input value={value.userPasswd} onBlur={() => { value.userPassShow = false; value.userPassStrength = 0 }}
              onFocus={(e) => { value.userPassShow = true; e.target.value && (value.userPassStrength = self.validatorPass(e.target.value)) } } style='width:240px'
              maxlength='16' type={ value.passwdType ? 'text' : 'password' } nativeOnInput={(e) => { e.target.value = e.target.value.replace(rule, '') }}
              onInput={(e) => { value.userPasswd = e; value.userPassStrength = self.validatorPass(e) }} placeholder='请输入新密码'>
              <i slot={'suffix'} onClick={() => { self.$refs.modifiedPass.$setValueField('passwdType', !value.passwdType) }}
                class={`${value.passwdType ? 'mps-icon icon-yanjing' : 'crm-icon icon-yanjing-bi'} mod-password-eyes`}>
              </i>
            </el-input>, <div v-show={ value.userPassShow } class='input-password-tips'><password-strength value={ value.userPassStrength } /><p class='password-tips'>6-16位，由字母（区分大小写）、数字、符号组成</p></div>]
          }
        }, {
          label: '确认新的登录密码',
          prop: 'userPasswdConfirm',
          render (h, store, value) {
            h = self.$createElement
            return [<el-input value={value.userPasswdConfirm} onBlur={() => { value.userPasswdConfirmShow = false; value.userPasswdConfirmStrength = 0 }}
              onFocus={(e) => { value.userPasswdConfirmShow = true; e.target.value && (value.userPasswdConfirmStrength = self.validatorPass(e.target.value)) } }
              style='width:240px' maxlength='16' type={ value.passwdConfirmType ? 'text' : 'password' } nativeOnInput={(e) => { e.target.value = e.target.value.replace(rule, '') }}
              onInput={(e) => { value.userPasswdConfirm = e; value.userPasswdConfirmStrength = self.validatorPass(e) }} placeholder='请再次输入新密码'>
              <i slot={'suffix'} onClick={() => { self.$refs.modifiedPass.$setValueField('passwdConfirmType', !value.passwdConfirmType) }}
                class={`${value.passwdConfirmType ? 'mps-icon icon-yanjing' : 'crm-icon icon-yanjing-bi'} mod-password-eyes`}>
              </i>
            </el-input>, <div v-show={ value.userPasswdConfirmShow } class='input-password-tips'><password-strength value={ value.userPasswdConfirmStrength } /><p class='password-tips'>6-16位，由字母（区分大小写）、数字、符号组成</p></div>]
          }
        }, {
          label: '',
          render (h, store, value) {
            return <el-button style='padding: 9px 36px;' type='primary' onClick={self.handleSubmitPass}>保存</el-button>
          }
        }],
        value: {
          userPasswd: null,
          userPassShow: false,
          userPassStrength: 0,
          passwdType: false,
          userPasswdConfirm: null,
          userPasswdConfirmShow: false,
          userPasswdConfirmStrength: 0,
          passwdConfirmType: false
        }
      }
    }
  },
  mounted () {
    this.shouldCheckTotal = (Number(cookie.getCookieUtil('_findpassword')) || 0) > 5
    this.$apis = new Server(this.$axios, this.$message.error)
  },
  methods: {
    setPhone (num, start = 3, length = 4) {
      const newNum = num + ''
      return newNum.slice(0, start) + '*'.repeat(length) + newNum.slice(newNum.length - start - 1)
    },
    validatorPass (pass) {
      if (pass) {
        if (pass.length < 6) {
          return 1
        }
        // eslint-disable-next-line no-useless-escape
        const regs = [/[0-9]/, /[A-Z]/, /[a-z]/, /[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~]/]
        let total = 0
        for (let i = 0; i < 4; i++) {
          if (regs[i].test(pass)) {
            total = total + 1
          }
        }
        if (total === 1) {
          return 1
        }
        if (total === 2) {
          return 2
        }
        if (total > 2) {
          return 3
        }
      } else {
        return 0
      }
    },
    handleSwitch (type) {
      if (type === 0) {
        this.$apis.getUserMessage(({ data }) => {
          this.phoneForm.account = data.zyxBandAccount
          this.type = type
          this.active = 0
        })
      } else {
        this.type = type
        this.active = 0
      }
    },
    onClearCodeInput (value) {
      this.phoneForm.code = value
      this.$nextTick(() => {
        this.phoneForm.code = value.replace(/\D/g, '')
      })
    },
    onGetCode (done) {
      this.$refs.phoneForm.validateField('account', (e) => {
        if (!e) {
          const total = Number(cookie.getCookieUtil('_findpassword')) || 0
          if (total === 5) {
            this.shouldCheckTotal = true
            cookie.setCookieUtil('_findpassword', 6, new Date(Date.now() + 5 * 60 * 1000))
            setTimeout(() => {
              this.shouldCheckTotal = false
            }, 5 * 60 * 1000)
            this.$refs.phoneForm.validateField('code', () => {})
          }
          if (total > 5) {
            this.$refs.phoneForm.validateField('code', () => {})
          }
          if (total < 5) {
            this.$refs.phoneForm.clearValidate('code')
            cookie.setCookieUtil('_findpassword', total + 1, new Date(Date.now() + 2 * 60 * 1000))
            this.$refs.phoneFormCode.focus()
            this.$apis.postPhoneCode({
              phone: this.phoneForm.account,
              type: 2
            }).catch(({ response }) => {
              Server.$message(this, response)
            })
            done && done()
          }
        }
      })
    },
    phoneSubmit (e) {
      if (this.phoneForm.account && this.phoneForm.code) {
        e.target.blur()
        this.onSubmitPhoneForm()
      }
    },
    onSubmitPhoneForm () {
      this.checkFormOfSubmit = true
      this.phoneCodeOk = true
      this.phoneCodeMsg = ''
      this.$refs.phoneForm.clearValidate()
      this.$refs.phoneForm.validate().then(res => {
        this.phoneloading = true
        this.$apis.checkPhoneCode({
          phone: this.phoneForm.account,
          type: 2,
          code: this.phoneForm.code
        }).then(() => {
          this.active = 1
        }).catch(({ response }) => {
          if (response && response.status === 400 && response.data && (response.data.msg === '验证码错误' || response.data.msg === '验证码已失效，请重新获取' || response.data.msg === '验证码已失效,请重新获取')) {
            this.phoneCodeOk = false
            this.phoneCodeMsg = response.data.msg
            this.$refs.phoneForm.validateField('code', () => {})
          } else {
            Server.$message(this, response)
          }
        }).finally(() => {
          this.phoneloading = false
        })
      }).catch(() => {
      }).finally(() => {
        this.checkFormOfSubmit = false
      })
    },
    handleCheckPass () {
      this.$refs.checkPass.$validate((validate) => {
        if (validate) {
          this.oldPassword = this.$refs.checkPass.$getValue().oldPassword
          this.active = 1
        }
      })
    },
    handleCancel () {
      this.active = -1
      this.type = null
    },
    handleSubmitPass () {
      if (this.type === 1) {
        this.$refs.modifiedPass.$validate((validate) => {
          if (validate) {
            const { userPasswd, userPasswdConfirm } = this.$refs.modifiedPass.$getValue()
            this.$apis.putModifyUser(this.$tools.auth.getUserId(), { oldPassword: this.oldPassword, userPasswd, userPasswdConfirm }).then(res => {
              this.active = 2
              this.modifiedPassSuccess()
            }).catch(response => {
              Server.$message(this, response)
            })
          }
        })
      } else {
        this.errMsg = ''
        this.$refs.modifiedPass.$refs.form.clearValidate()
        this.$refs.modifiedPass.$refs.form.validate().then(res => {
          this.loading = true
          const { userPasswd, userPasswdConfirm } = this.$refs.modifiedPass.$getValue()
          this.$apis.findPassword({
            phone: this.phoneForm.account,
            code: this.phoneForm.code,
            userPasswd,
            userPasswdConfirm
          }).then(res => {
            this.active = 2
            this.modifiedPassSuccess()
          }).catch(({ response }) => {
            if (response && response.status === 400 && response.data && (response.data.msg === '验证码已失效，请重新获取' || response.data.msg === '验证码已失效,请重新获取')) {
              this.errMsg = response.data.msg
              this.$refs.modifiedPass.validateField('passwordConfirm', () => {})
            } else {
              Server.$message(this, response)
            }
          }).finally(() => {
            this.loading = false
          })
        })
      }
    },
    modifiedPassSuccess () {
      if (this.countdown) {
        setTimeout(() => {
          this.countdown = this.countdown - 1
          this.modifiedPassSuccess()
        }, 1000)
      } else {
        this.logout()
      }
    },
    async logout () {
      await this.$axios.get('/api/logout', { headers: { token: window.global.$tools.auth.getToken() }})
      this.$tools.auth.clearToken()
      window.sessionStorage.clear()
      window.location.href = '//sso.shijqq.com/#/auth?backUrl=' + encodeURIComponent(window.location.origin)
    }
  }
}
</script>

<style lang="less">
.mod-password-modify{
  padding: 20px;
  position: relative;
  .setting-container{
    width:800px;
    margin: 80px auto;
    .el-step__head{
      .el-step__line{
        box-sizing: border-box;
        border-bottom: 2px dashed;
        background-color: transparent;
      }
      &.is-process{
        color: #409eff;
        border-color: #409eff;
        .el-step__line{
          border-color: #c0c4cc;
        }
      }
      &.is-finish{
      color: #409eff;
      border-color: #409eff;
        .el-step__line{
          border-color: #409eff;
        }
        .el-step__line-inner{
          width: 0 !important;
        }
      }
    }
    .el-step__main{
      .el-step__title.is-process{
        font-weight: normal;
        color: #409eff;
      }
      .el-step__description{
        margin-top: 0;
      }
    }
    .switch-type{
      margin: 80px 100px;
      .title{
        font-weight: normal;
        font-style: normal;
        font-size: 16px;
      }
      .type-item{
        width: 100%;
        height: 100px;
        border-radius: 8px;
        border: 2px solid #efefef;
        position: relative;
        .info{
          width: 100%;
          height: 100px;
          box-sizing: border-box;
          padding-right: 200px;
          padding-left: 20px;
          .sub-title{
            color: #333;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 100px;
            margin: 0;
          }
          .tips{
            color: #999;
            font-size: 12px;
          }
        }
        .option{
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          height: 100px;
          width: 200px;
          line-height: 100px;
          text-align: center;
        }
        &:last-child{
          margin-top: 30px;
        }
        &:hover{
          box-shadow: 0 1px 10px 0 #C3C3C3;
          border-color: none;
        }
      }
    }
    .mobile-password{
      width: 400px;
      margin: 60px auto 0;
      .input-code{
        width: 240px;
        .el-input__inner{
          padding-left: 10px;
          padding-right: 100px;
        }
      }
      .tips-area{
        width: 400px;
        background: #f6f6f6;
        border-radius: 6px;
        box-sizing: border-box;
        padding: 20px 30px;
        margin: 20px auto;
        .title{
          margin: 0 auto 4px;
          font-style: normal;
          font-weight: normal;
          color: #333;
          font-size: 14px;
        }
        .text-area, li{
          list-style: none;
          padding: 0;
          margin: 0;
          font-size: 12px;
          color: #666;
          line-height: 18px;
        }
      }
    }
    .check-old-pass{
      margin: 60px auto 0;
      .button-area{
        text-align: center;
        padding: 10px 0;
        .el-button{
          padding: 9px 30px;
        }
      }
      .tips-area{
        width: 400px;
        background: #f6f6f6;
        border-radius: 6px;
        box-sizing: border-box;
        padding: 20px 25px;
        margin: 20px auto;
        .title{
          margin: 0 auto 4px;
          font-style: normal;
          font-weight: normal;
          color: #333;
          font-size: 14px;
        }
        .text-area, li{
          list-style: none;
          padding: 0;
          margin: 0;
          font-size: 12px;
          color: #666;
          line-height: 18px;
        }
      }
    }
    .modified-pass{
      margin: 60px auto 0;
      .input-password-tips{
        line-height: 16px;
        position: absolute;
        top: 0;
        width: 318px;
        left: 250px;
      }
    }
    .modified-success{
      width: 540px;
      height: 88px;
      margin: 60px auto 0;
      box-sizing: border-box;
      background: #f0f9eb;
      border-radius: 6px;
      position: relative;
      .success-icon{
        color: #67c23a;
        font-size: 36px;
        position: absolute;
        top: 0;
        left: 20px;
        bottom: 0;
        line-height: 88px;
      }
      .tips-area{
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        padding: 24px 70px;
        .title{
          margin: 0;
          font-size: 16px;
          color: #333;
          font-weight: normal;
          font-style: normal;
        }
        .text{
          padding-top: 4px;
          font-size: 14px;
          color: #666;
          .count-down{
            color: #00a5fa;
            padding-right: 4px;
          }
        }
      }
    }
  }
}
</style>
